import {ILocationMarker} from '@tehzor/tools/interfaces/ILocationMarker';
import {markerProblemPath, markerStructurePath} from '../utils/markerPath';
import {Path} from '../PlanViewer';
import classNames from 'classnames';

interface IPointProps {
	index: number;
	point: ILocationMarker;
	path: Path;
	scale: number;
	selectedPointIndex: number | null;
	tempPoint: {
		x: number;
		y: number;
	} | null;
	singleSelectedPoint?: number;
	pointsColor: string;
	handlePointMouseDown: (event: React.MouseEvent<SVGElement, MouseEvent>, index: number) => void;
}

export const Point = ({
	index,
	point,
	path,
	scale,
	selectedPointIndex,
	tempPoint,
	singleSelectedPoint,
	pointsColor,
	handlePointMouseDown
}: IPointProps) => {
	const d =
		path === 'problem'
			? markerProblemPath(0, 0, 0.5 / scale)
			: markerStructurePath(0, 0, 0.5 / scale);
	const isDragging = selectedPointIndex === index && tempPoint;
	const x = isDragging ? tempPoint.x : point.x;
	const y = isDragging ? tempPoint.y : point.y;
	const pointClassNames = ['plan-viewer__point_problem'];

	if (singleSelectedPoint !== undefined) {
		if (singleSelectedPoint === index) {
			pointClassNames.push('plan-viewer__point_selected-point');
		} else {
			pointClassNames.push('plan-viewer__point_disabled');
		}
	}

	const pointElementDescriptionMarker =
		point.description && x && y ? (
			<circle
				r={10 / scale}
				className="plan-viewer__point_description-marker"
				cx={x + 10 / scale}
				cy={y - 35 / scale}
				strokeWidth={3 / scale}
			/>
		) : null;

	return (
		<>
			<path
				className={classNames(pointClassNames)}
				d={d}
				transform={`translate(${x}, ${y})`}
				key={point.id}
				fill={pointsColor}
				strokeWidth={3 / scale}
				onMouseDown={event => handlePointMouseDown(event, index)}
			/>
			{pointElementDescriptionMarker}
		</>
	);
};
