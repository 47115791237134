import {ILocationMarker} from '@tehzor/tools/interfaces/ILocationMarker';
import ILayer from '@tehzor/tools/interfaces/plans/ILayer';
import IShape from '@tehzor/tools/interfaces/plans/IShape';
import classNames from 'classnames';
import {useCallback, useMemo, useRef, useState, WheelEventHandler} from 'react';
import {Point} from './components/Point';
import {Sector} from './components/Sector';
import './PlanViewer.less';
import {getPointAttributes} from './utils/getPointAttributes';
import {markerStructurePath} from './utils/markerPath';

export type Mode = 'view' | 'edit';

export type InputType = 'sectors' | 'points';

export type Path = 'problem' | 'entity';

interface ILayerWithShapes extends Omit<ILayer, 'shapes'> {
	shapes: IShape[];
}

interface IPlanViewerProps {
	mode: Mode;
	inputType: InputType;
	image?: string;
	layers?: ILayer[];
	visibleLayers: string[];
	sectors: ILocationMarker[];
	points: ILocationMarker[];
	multiplePoints?: boolean; // TODO выяснить как это использовалось
	singleSelectedPoint?: number;
	pointsColor?: string;
	path: Path;
	entitySectors?: ILocationMarker[];
	entityPoints?: ILocationMarker[];
	selectPoint?: (point: number) => void;
	selectSector?: (sector: number) => void;
	onSectorsChange?(shapes: ILocationMarker[]): void;
	onPointsChange?(points: ILocationMarker[]): void;
}

/**
 * Компонент для просмотра плана и выбора секторов и точек
 */
export const PlanViewer = ({
	mode,
	inputType,
	image,
	layers,
	visibleLayers,
	sectors,
	points,
	// multiplePoints = false,
	singleSelectedPoint,
	pointsColor = '#FF8228',
	path,
	entitySectors,
	entityPoints,
	selectPoint,
	selectSector,
	onSectorsChange,
	onPointsChange
}: IPlanViewerProps) => {
	const [tempPoint, setTempPoint] = useState<{x: number; y: number} | null>(null);
	const [selectedPointIndex, setSelectedPointIndex] = useState<number | null>(null);
	const [initialClick, setInitialClick] = useState<{x: number; y: number} | null>(null);
	const [scale, setScale] = useState<number>(1);
	const [translate, setTranslate] = useState<{x: number; y: number}>({x: 0, y: 0});
	const [dragging, setDragging] = useState<boolean>(false);
	const svgRef = useRef<SVGSVGElement | null>(null);
	const wrapperRef = useRef<HTMLDivElement | null>(null);
	const frameRef = useRef<HTMLDivElement | null>(null);
	const sectorIds = useMemo(() => sectors.map(sector => sector.sector), [sectors]);
	const visibleLayersWithShapes = useMemo(
		() =>
			layers?.filter((layer: ILayer) => visibleLayers.includes(layer.id) && layer.shapes) as
				| ILayerWithShapes[]
				| undefined,
		[layers, visibleLayers]
	);

	// Функция для проверки, отличается ли svg ctm.a и svg ctm.d от scale с учетом погрешности
	const isCtmScalingApplied = (ctm: DOMMatrix, currentScale: number, epsilon = 0.001): boolean =>
		Math.abs(ctm.a - currentScale) > epsilon && Math.abs(ctm.d - currentScale) > epsilon;

	const onWheelFrameHandler: WheelEventHandler<HTMLDivElement> = event => {
		event.preventDefault();
	};

	const handleSectorMouseDown = useCallback(
		(event: React.MouseEvent<SVGElement, MouseEvent>, sectorName: string, sectorId: string) => {
			event.stopPropagation();

			const index = sectorIds.findIndex(id => id === sectorId);

			if (index > -1) {
				if (selectSector) {
					selectSector(index);
				}
				if (onSectorsChange) {
					onSectorsChange(
						sectors.map(sector =>
							sector.sector === sectorId
								? {...sector, selected: true}
								: {...sector, selected: false}
						)
					);
				}
			} else if (onSectorsChange && mode === 'edit') {
				const newSector = {
					name: sectorName,
					sector: sectorId
				};
				onSectorsChange([...sectors, newSector]);
			}

			setInitialClick({x: event.clientX, y: event.clientY});
		},
		[selectSector, onSectorsChange, setInitialClick, sectorIds, sectors, mode]
	);

	const handlePointMouseDown = useCallback(
		(event: React.MouseEvent<SVGElement, MouseEvent>, index: number) => {
			event.stopPropagation();
			setSelectedPointIndex(index);
			if (mode === 'edit') {
				setInitialClick({x: event.clientX, y: event.clientY});
			}
		},
		[setSelectedPointIndex, setInitialClick, mode]
	);

	const handleBackgroundMouseDown = (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
		setDragging(true);
		setInitialClick({x: event.clientX, y: event.clientY});
	};

	const createPoint = useCallback(
		(pointX: number, pointY: number, svg: SVGSVGElement) => {
			const pointDOM = new DOMPoint(pointX, pointY);

			let ctm = svg.getScreenCTM();

			// Проверяем применён ли scale к SVG CTM, если нет то применяем,
			// т.к. Safari в настоящее время не учитывает преобразования (scale, rotate) родительских элементов
			if (ctm && isCtmScalingApplied(ctm, scale)) {
				ctm = ctm.scale(scale);
			}

			const {x, y} = pointDOM.matrixTransform(ctm?.inverse());

			const {sector, name} = getPointAttributes(x, y, layers);
			const countBySector = points.filter(p => p.sector === sector).length;

			const newPoint: ILocationMarker = {
				name: countBySector ? `${name} (${countBySector})` : name,
				sector,
				x,
				y
			};
			if (onPointsChange) {
				onPointsChange([...points, newPoint]);
			}
		},
		[layers, onPointsChange, points, scale]
	);

	const handleMouseUp = useCallback(
		(event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
			if (selectedPointIndex !== null) {
				if (
					mode === 'view' ||
					(initialClick &&
						initialClick.x === event.clientX &&
						initialClick.y === event.clientY)
				) {
					if (selectPoint) {
						selectPoint(selectedPointIndex);
					}
				} else {
					const svg = svgRef.current;
					if (svg && tempPoint && mode === 'edit') {
						const {sector, name} = getPointAttributes(tempPoint.x, tempPoint.y, layers);

						const updatedPoints: ILocationMarker[] = points.map((p, index) =>
							index === selectedPointIndex
								? {...p, x: tempPoint.x, y: tempPoint.y, sector, name}
								: p
						);

						if (onPointsChange) {
							onPointsChange(updatedPoints);
						}
					}
					setTempPoint(null);
				}
				setSelectedPointIndex(null);
			} else if (dragging) {
				if (
					initialClick &&
					initialClick.x === event.clientX &&
					initialClick.y === event.clientY
				) {
					const svg = svgRef.current;
					if (svg && mode === 'edit') {
						createPoint(event.clientX, event.clientY, svg);
					}
				}
			}
			setDragging(false);
		},
		[
			dragging,
			initialClick,
			mode,
			onPointsChange,
			points,
			selectPoint,
			selectedPointIndex,
			tempPoint,
			layers,
			createPoint
		]
	);

	const handleMouseMove = useCallback(
		(event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
			if (selectedPointIndex !== null && mode === 'edit') {
				const svg = svgRef.current;
				if (svg) {
					const pointDOM = new DOMPoint(event.clientX, event.clientY);

					let ctm = svg.getScreenCTM();

					// Проверяем применён ли scale к SVG CTM, если нет то применяем,
					// т.к. Safari в настоящее время не учитывает преобразования (scale, rotate) родительских элементов
					if (ctm && isCtmScalingApplied(ctm, scale)) {
						ctm = ctm.scale(scale);
					}

					const {x, y} = pointDOM.matrixTransform(ctm?.inverse());

					setTempPoint({x, y});
				}
			} else if (dragging) {
				setTranslate({
					x: translate.x + event.movementX,
					y: translate.y + event.movementY
				});
			}
		},
		[dragging, mode, scale, selectedPointIndex, translate.x, translate.y]
	);

	const handleWheel = useCallback(
		(event: React.WheelEvent<SVGSVGElement>) => {
			event.preventDefault();
			const delta = event.deltaY > 0 ? 0.9 : 1.1;
			setScale(scale * delta);
		},
		[setScale, scale]
	);

	const handleImageLoad = (event: React.SyntheticEvent<SVGImageElement, Event>) => {
		const href = (event.currentTarget as SVGImageElement).getAttribute('href');
		if (!href) return;

		const img = new Image();
		img.src = href;
		img.onload = () => {
			const width = img.naturalWidth;
			const height = img.naturalHeight;

			if (wrapperRef.current && svgRef.current) {
				svgRef.current.style.width = `${width}px`;
				svgRef.current.style.height = `${height}px`;
			}

			const frame = frameRef.current;
			if (frame) {
				const frameWidth = frame.clientWidth;
				const frameHeight = frame.clientHeight;
				const scale = Math.min(frameWidth / width, frameHeight / height);

				setScale(scale);

				setTranslate({
					x: (frameWidth - width) / 2,
					y: (frameHeight - height) / 2
				});
			}
		};
	};

	const elements = useMemo(() => {
		switch (inputType) {
			case 'points':
				return points.map((point, i) => (
					<Point
						index={i}
						point={point}
						path={path}
						scale={scale}
						selectedPointIndex={selectedPointIndex}
						tempPoint={tempPoint}
						singleSelectedPoint={singleSelectedPoint}
						pointsColor={pointsColor}
						handlePointMouseDown={handlePointMouseDown}
					/>
				));
			case 'sectors':
				const entitySectorIds = entitySectors?.map(sector => sector.id);
				const commentedSectors = sectors.filter(el => el.description).map(el => el.sector);
				return visibleLayersWithShapes
					? visibleLayersWithShapes
							.map(layer =>
								layer.shapes.map(shape => (
									<Sector
										shape={shape}
										sectorIds={sectorIds}
										commentedSectors={commentedSectors}
										entitySectorIds={entitySectorIds}
										handleSectorMouseDown={handleSectorMouseDown}
									/>
								))
							)
							.flat()
					: null;

			default:
				return null;
		}
	}, [
		inputType,
		points,
		scale,
		pointsColor,
		handlePointMouseDown,
		path,
		selectedPointIndex,
		tempPoint,
		sectors,
		handleSectorMouseDown,
		sectorIds,
		singleSelectedPoint,
		visibleLayersWithShapes,
		entitySectors
	]);

	const entityElements = useMemo(
		() =>
			entityPoints?.map(point => {
				const d = markerStructurePath(0, 0, 0.5 / scale);
				const x = point.x;
				const y = point.y;
				const pointClassNames = ['plan-viewer__point_structure'];

				return (
					<path
						className={classNames(pointClassNames)}
						d={d}
						transform={`translate(${x}, ${y})`}
						key={point.id}
						strokeWidth={3 / scale}
						strokeDasharray={3.5 / scale}
					/>
				);
			}),

		[entityPoints, scale]
	);

	return (
		<div className="plan-viewer__frame" ref={frameRef} onWheel={onWheelFrameHandler}>
			<div className="plan-viewer__wrapper" ref={wrapperRef}>
				<svg
					ref={svgRef}
					className="plan-viewer__svg"
					style={{
						cursor: dragging ? 'auto' : 'grab',
						transform: `translate(${translate.x}px, ${translate.y}px) scale(${scale})`,
						transformOrigin: 'center'
					}}
					onMouseMove={handleMouseMove}
					onMouseUp={handleMouseUp}
					onMouseDown={handleBackgroundMouseDown}
					onWheel={handleWheel}
				>
					<>
						<image onLoad={handleImageLoad} href={image} />
						{elements}
						{entityElements}
					</>
				</svg>
			</div>
		</div>
	);
};
